/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// @file Logic for the NWjs desktop app

const PROD_CHROME_EXTENSION_ID = 'kcljbbiddpoeaknnjaminoceoojdbikp'
const DEV_CHROME_EXTENSION_ID = 'dkioigicbijecidbooccnhfafineggga'

const isPadletInternal = function (hostname) {
  const ALLOWED_HOSTS = ['PADLET.COM', 'PADLET.ORG', 'PADLET.TEST', 'XIP.IO', 'LOCALTUNNEL.ME']
  for (let i in ALLOWED_HOSTS) {
    if (hostname.toUpperCase().includes(ALLOWED_HOSTS[i])) {
      return true
    }
  }
  return false
}

const clearAppData = function (callback) {
  const forever = 0
  return chrome.browsingData.remove(
    {
      since: forever,
    },
    {
      appcache: true,
      cache: true,
      cookies: true,
      downloads: true,
      fileSystems: true,
      formData: true,
      history: true,
      indexedDB: true,
      localStorage: true,
      pluginData: true,
      passwords: true,
      webSQL: true,
    },
    callback,
  )
}

const overrideAnchorTags = function (defaultOpen, openInNewWindow, openInBrowser) {
  return document.addEventListener('click', function (event) {
    if (event.currentTarget && event.currentTarget.activeElement && event.currentTarget.activeElement.tagName === 'A') {
      const anchorElement = event.currentTarget.activeElement
      if (anchorElement.dataset.appTarget) {
        event.preventDefault()
        switch (anchorElement.dataset.appTarget) {
          case 'none':
            break
          case 'self':
            return defaultOpen(anchorElement.href)
          case 'blank':
            return openInNewWindow(anchorElement.href)
          case 'browser':
            return openInBrowser(anchorElement.href)
        }
      } else {
        event.preventDefault()
        if (
          anchorElement.baseURI.toUpperCase().includes('/AUTH/') ||
          anchorElement.href.toUpperCase().includes('/AUTH/')
        ) {
          return defaultOpen(anchorElement.href)
        } else {
          return openInBrowser(anchorElement.href)
        }
      }
    }
  })
}

const manuallyAddHtmlClass = function (extra) {
  document.firstElementChild.classList += ' desktopapp'
  if (extra) {
    document.firstElementChild.classList += ' ' + extra
  }
}

const shouldOpenNewWindow = function (url) {
  const allWindows = chrome.app.window.getAll()
  for (let i in allWindows) {
    const openedUrl = allWindows[i].contentWindow.location.href
    if (url === openedUrl) {
      allWindows[i].focus()
      return false
    }
  }
  return true
}

const computeNewWindowLocation = function (callback) {
  let newX = -1
  let newY = -1
  const currentActiveWindow = chrome.app.window.current() || chrome.app.window.getAll()[0]
  const allWindows = chrome.app.window.getAll()
  const allBounds = []
  if (currentActiveWindow) {
    newX = (currentActiveWindow.x || currentActiveWindow.innerBounds.left) + 20
    newY = (currentActiveWindow.y || currentActiveWindow.innerBounds.top) + 20
  }
  for (let i in allWindows) {
    const win = allWindows[i]
    if (win) {
      allBounds.push({
        x: win.innerBounds.left,
        y: win.innerBounds.top,
      })
    }
  }
  allBounds.sort(function (a, b) {
    if (a.x < b.x) {
      return -1
    }
    if (a.x > b.x) {
      return 1
    }
    return 0
  })
  for (let i in allBounds) {
    bounds = allBounds[i]
    if (newX === bounds.x) {
      console.log('increase')
    }
    newX = newX === bounds.x ? bounds.x + 20 : newX
    newY = newY === bounds.y ? bounds.y + 20 : newY
  }
  newX = newX === -1 ? null : newX
  newY = newY === -1 ? null : newY
  return callback({
    x: newX,
    y: newY,
  })
}

const getCookie = function (cookieName) {
  const _ref = RegExp('' + cookieName + '[^;]+').exec(document.cookie)
  return decodeURIComponent(_ref != null ? _ref.toString().replace(/^[^=]+./, '') : void 0)
}

const getTitleBarColor = function (rgb) {
  const percent = -0.15
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = rgb[0]
  const G = rgb[1]
  const B = rgb[2]
  return [Math.round((t - R) * p) + R, Math.round((t - G) * p) + G, Math.round((t - B) * p) + B]
}

const componentToHex = function (c) {
  const hex = c.toString(16)
  if (hex.length === 1) {
    return '0' + hex
  } else {
    return hex
  }
}

const toHex = function (rgb) {
  return '#' + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2])
}

const decideAppBarColor = function (url) {
  const defaultColor = {
    color: '#ebebeb',
    inactiveColor: '#f8f8f8',
  }
  return new Promise(function (resolve, reject) {
    const u = new URL(url)
    return fetch(u.origin + '/walls/address:' + u.pathname.slice(1))
      .then(function (res) {
        return res.json()
      })
      .then(function (wallData) {
        return resolve({
          color: toHex(getTitleBarColor(wallData.background.dominant_color)),
          inactiveColor: toHex(wallData.background.dominant_color),
        })
      })
      ['catch'](function (error) {
        return resolve(defaultColor)
      })
  })
}

const initializeAlertFunction = function () {
  return window.alert
}

const initializeConfirmFunction = function () {
  return window.confirm
}

const initializePromptFunction = function () {
  return window.prompt
}

const initialize = function () {
  window.require =
    window.require ||
    function (packageName) {
      console.log('Cannot load' + packageName + '. The page should be loaded in NWJS app or some node environment')
      return (function () {
        function _Class() {}
        return _Class
      })()
    }
  window.process = window.process || {
    cwd: function () {},
  }
  window.nw = window.nw || {
    App: {},
  }
  window.alert = initializeAlertFunction()
  window.confirm = initializeConfirmFunction()
  window.prompt = initializePromptFunction()
  window.$desktopApp = {}
  window.addEventListener('load', function () {
    let defaultOpen, desktopApp, electronApp, nwjsApp, openInBrowser, openInNewWindow, _ref, _ref1, _ref2, _ref3
    if (typeof chrome !== 'undefined' && chrome !== null) {
      if ((_ref = chrome.runtime) != null) {
        if (typeof _ref.sendMessage === 'function') {
          _ref.sendMessage(PROD_CHROME_EXTENSION_ID, {
            status: 'loaded',
          })
        }
      }
    }
    if (typeof chrome !== 'undefined' && chrome !== null) {
      if ((_ref1 = chrome.runtime) != null) {
        if (typeof _ref1.sendMessage === 'function') {
          _ref1.sendMessage(DEV_CHROME_EXTENSION_ID, {
            status: 'loaded',
          })
        }
      }
    }
    if (typeof chrome !== 'undefined' && chrome !== null) {
      if ((_ref2 = chrome.runtime) != null) {
        if ((_ref3 = _ref2.onMessage) != null) {
          _ref3.addListener(function (request, sender, sendResponse) {
            if (request.command) {
              if (request.command === 'setMenu') {
                return (nw.Window.get().menu = request.menu)
              } else if (request.command === 'actualSize') {
                return (nw.Window.get().zoomLevel = 0)
              } else if (request.command === 'zoomIn') {
                return nw.Window.get().zoomLevel++
              } else if (request.command === 'zoomOut') {
                return nw.Window.get().zoomLevel--
              } else if (request.command === 'fullScreen') {
                return nw.Window.get().toggleFullscreen()
              }
            }
          })
        }
      }
    }
    if (typeof Windows !== 'undefined') {
      defaultOpen = function (url, target) {
        return window.open(url, target || '_top')
      }
      openInNewWindow = function (url) {
        return window.open(url, '_blank')
      }
      openInBrowser = function (url) {
        return Windows.System.Launcher.launchUriAsync(new Windows.Foundation.Uri(url))
      }
      overrideAnchorTags(defaultOpen, openInNewWindow, openInBrowser)
      manuallyAddHtmlClass()
    }
    desktopApp = window.ww && ww.device && ww.device.desktopApp
    desktopApp ||
      (desktopApp =
        document.documentElement.dataset.variant && document.documentElement.dataset.variant.includes('desktopapp'))
    electronApp = false
    nwjsApp = window.ww && ww.device && ww.device.nwjs
    nwjsApp ||
      (nwjsApp = document.documentElement.dataset.variant && document.documentElement.dataset.variant.includes('nwjs'))
    if (desktopApp) {
      if (nwjsApp) {
        manuallyAddHtmlClass('nwjs')
        defaultOpen = function (url, target) {
          if (!target || target === '_top') {
            window.location.href = url
          }
          if (target === '_blank') {
            return window.open(url, '_blank')
          }
        }
        ww.navigateDesktopApp = defaultOpen
        openInNewWindow = function (url) {
          if (shouldOpenNewWindow(url)) {
            return decideAppBarColor(url).then(function (titleBarColor) {
              return computeNewWindowLocation(function (bounds) {
                var boundOptions, createWindowOptions
                boundOptions = {
                  width: 1280,
                  height: 800,
                  minWidth: 400,
                  minHeight: 200,
                  left: bounds.x,
                  top: bounds.y,
                }
                createWindowOptions = {
                  innerBounds: boundOptions,
                  frame: {
                    type: 'chrome',
                    color: titleBarColor.color,
                    inactiveColor: titleBarColor.inactiveColor,
                  },
                }
                chrome.app.window.create(url, createWindowOptions)
              })
            })
          }
        }
        openInBrowser = function (url) {
          return nw.Shell.openExternal(url)
        }
        overrideAnchorTags(defaultOpen, openInNewWindow, openInBrowser)
      } else if (electronApp) {
        defaultOpen = function (url, target) {
          if (!target || target === '_top') {
            window['__macAppMsgCenter'].bridge.navigate(url)
          }
          if (target === '_blank') {
            return window['__macAppMsgCenter'].bridge.openNewWindowInApp(url)
          }
        }
        openInNewWindow = function (url) {
          return window['__macAppMsgCenter'].bridge.openNewWindowInApp(url)
        }
        openInBrowser = function (url) {
          return window['__macAppMsgCenter'].bridge.openInBrowser(url)
        }
        overrideAnchorTags(defaultOpen, openInNewWindow, openInBrowser)
      }
    }
  })
}

initialize()
